import FONT_SRC_5X5 from "url:./5x5.png";
import FONT_SRC_5X8 from "url:./5x8.png";
import FONT_SRC_8X8 from "url:./8x8.png";

(window as any).ID = Date.now();

interface WasmExports {
  memory: WebAssembly.Memory;
  MEM: WebAssembly.Global; // [u8; 4096]
  TICK: WebAssembly.Global; // i32
  RAND: WebAssembly.Global; // u32
  SCREEN: WebAssembly.Global; // [i32; 2]
  CURSOR: WebAssembly.Global; // [i32; 3]
  GAMEPADS: WebAssembly.Global; // [i32; 44]
  PALETTE: WebAssembly.Global; // [u32; 32]
  GRAPHICS: WebAssembly.Global; // Vec<i32>
  GRAPHICS_LEN: WebAssembly.Global; // i32
  CLEAR_COLOR: WebAssembly.Global; // i32
  SPRITESHEET: WebAssembly.Global; // Vec<u8>
  SPRITESHEET_LEN: WebAssembly.Global; // i32
  // PUBLIC_KEY?: WebAssembly.Global;
  graphics(): number;
  graphics_len(): number;
  run(): void;
  _start?(): void;
}

async function main() {
  const WINDOW_ID = (window as any).ID;

  const CANVAS_WIDTH = parseInt(
    Math.min(422, Math.max(195, window.innerWidth / 2)),
    10
  );
  const CANVAS_HEIGHT = parseInt(
    Math.min(422, Math.max(195, window.innerHeight / 2)),
    10
  );

  console.log(CANVAS_WIDTH, CANVAS_HEIGHT);

  let CANVAS_SCALE = 1;

  enum InputState {
    Released = 0,
    JustPressed = 1,
    Pressed = 2,
    JustReleased = 3,
  }

  const params = new URLSearchParams(window.location.search.substring(1));
  if (params.has("clear_memory")) {
    localStorage.clear();
  }
  const wasm_url = params.get("rom") ?? "foo.wasm";
  const cover_url = wasm_url + ".png";
  let res = await fetch(wasm_url);
  const buf = await res.arrayBuffer();
  const obj = await WebAssembly.instantiate(buf, {
    wagmi2d: {
      on_write() {
        const view = new Uint8Array(memory.buffer, exports.MEM.value, 4096);
        const data = [...view];
        localStorage.setItem("MEM", JSON.stringify(data));
      },
      random() {
        return crypto.getRandomValues(new Uint8Array(4))[0];
      },
      log(ptr_text: number) {
        const mem = new Uint8Array(memory.buffer);
        let text = "";
        for (let i = ptr_text, j = 0; mem[i] !== 0; i++, j++) {
          text += String.fromCharCode(mem[i]);
        }
        console.log(text);
      },
    },
  });

  const exports = obj.instance.exports as any as WasmExports;
  const memory = exports.memory;
  const mem = new DataView(memory.buffer);

  // Hydrate persistent data
  try {
    const data: number[] =
      JSON.parse(localStorage.getItem("MEM") ?? "[]") ?? [];
    const bytes = Uint8Array.from(data);
    const view = new Uint8Array(memory.buffer, exports.MEM.value, 4096);
    for (let i = 0; i < bytes.length; i++) {
      view[i] = bytes[i];
    }
  } catch (err) {
    console.error(err);
  }

  // GLOBALS
  // --------------------------------------------------------------------------
  const PALETTE = [
    ...new Uint32Array(memory.buffer, exports.PALETTE.value, 32),
  ];
  const PALETTE_HEX = PALETTE.reduce((pal, argb) => {
    // const a = (argb & 0xff000000) >> 24;
    const r = (argb & 0x00ff0000) >> 16;
    const g = (argb & 0x0000ff00) >> 8;
    const b = argb & 0x000000ff;
    const hex = "#" + r.toString(16) + g.toString(16) + b.toString(16);
    pal.push(hex);
    return pal;
  }, [] as string[]);

  // console.log(PALETTE_HEX);

  const updateScreen = () => {
    const SCREEN = new Int32Array(memory.buffer, exports.SCREEN.value, 2);
    SCREEN[0] = CANVAS_WIDTH;
    SCREEN[1] = CANVAS_HEIGHT;
  };
  const updateCursor = () => {
    const CURSOR = new Int32Array(memory.buffer, exports.CURSOR.value, 3);
    CURSOR[0] = MOUSE_STATE;
    CURSOR[1] = MOUSE_X;
    CURSOR[2] = MOUSE_Y;
  };
  const handleButtonPress = (state: InputState, pressed: boolean) => {
    // prettier-ignore
    switch (state) {
      case InputState.Released:
        return pressed ? InputState.JustPressed : InputState.Released;
      case InputState.JustPressed:
        return pressed ? InputState.Pressed : InputState.JustReleased;
      case InputState.Pressed:
        return pressed ? InputState.Pressed : InputState.JustReleased;
      case InputState.JustReleased:
        return pressed ? InputState.JustPressed : InputState.Released;
    }
  };
  const updateGamepad = () => {
    // 44 bytes per gamepad
    // is_connected / up / down / left / right / a / b / x / y / select / start
    const GAMEPADS = new Int32Array(memory.buffer, exports.GAMEPADS.value, 8);
    const gamepads = navigator.getGamepads();
    for (let i = 0; i < 4; i++) {
      const n = i * 11;
      const gamepad = gamepads[i];
      if (!gamepad) {
        GAMEPADS[n] = 0;
      } else {
        // prettier-ignore
        {
        // Is Connected?
        GAMEPADS[n + 0] = 1;
        // Up
        GAMEPADS[n + 1] = handleButtonPress(GAMEPADS[n + 1], gamepad.buttons[12].pressed);
        // Down
        GAMEPADS[n + 2] = handleButtonPress(GAMEPADS[n + 2], gamepad.buttons[13].pressed);
        // Left
        GAMEPADS[n + 3] = handleButtonPress(GAMEPADS[n + 3], gamepad.buttons[14].pressed);
        // Right
        GAMEPADS[n + 4] = handleButtonPress(GAMEPADS[n + 4], gamepad.buttons[15].pressed);
        // A
        GAMEPADS[n + 5] = handleButtonPress(GAMEPADS[n + 5], gamepad.buttons[0].pressed);
        // B
        GAMEPADS[n + 6] = handleButtonPress(GAMEPADS[n + 6], gamepad.buttons[1].pressed);
        // X
        GAMEPADS[n + 7] = handleButtonPress(GAMEPADS[n + 7], gamepad.buttons[2].pressed);
        // Y
        GAMEPADS[n + 8] = handleButtonPress(GAMEPADS[n + 8], gamepad.buttons[3].pressed);
        // SELECT
        GAMEPADS[n + 9] = handleButtonPress(GAMEPADS[n + 9], gamepad.buttons[9].pressed);
        // START
        GAMEPADS[n + 10] = handleButtonPress(GAMEPADS[n + 10], gamepad.buttons[8].pressed);
        }
      }
    }
    for (
      let update = KEYBOARD_GAMEPAD_UPDATES.shift();
      update;
      update = KEYBOARD_GAMEPAD_UPDATES.shift()
    ) {
      update();
    }
  };
  const KEYBOARD_GAMEPAD_UPDATES: any[] = [];
  // keyboard gamepad
  document.onkeydown = (e) => {
    KEYBOARD_GAMEPAD_UPDATES.push(() => {
      const GAMEPADS = new Int32Array(memory.buffer, exports.GAMEPADS.value, 8);
      const n = 0 * 11; // gamepad id
      GAMEPADS[n + 0] = 1;
      switch (e.key) {
        case "ArrowUp":
        case "w": // up
          GAMEPADS[n + 1] = handleButtonPress(GAMEPADS[n + 1], true);
          break;
        case "ArrowDown":
        case "s": // down
          GAMEPADS[n + 2] = handleButtonPress(GAMEPADS[n + 2], true);
          break;
        case "ArrowLeft":
        case "a": // left
          GAMEPADS[n + 3] = handleButtonPress(GAMEPADS[n + 3], true);
          break;
        case "ArrowRight":
        case "d": // right
          GAMEPADS[n + 4] = handleButtonPress(GAMEPADS[n + 4], true);
          break;
        case "x": // a
          GAMEPADS[n + 5] = handleButtonPress(GAMEPADS[n + 5], true);
          break;
        case "z": // b
          GAMEPADS[n + 6] = handleButtonPress(GAMEPADS[n + 6], true);
          break;
        case "c": // x
          GAMEPADS[n + 7] = handleButtonPress(GAMEPADS[n + 7], true);
          break;
        case "v": // y
          GAMEPADS[n + 8] = handleButtonPress(GAMEPADS[n + 8], true);
          break;
        case " ": // start
          GAMEPADS[n + 9] = handleButtonPress(GAMEPADS[n + 9], true);
          break;
        case "Enter": // select
          GAMEPADS[n + 10] = handleButtonPress(GAMEPADS[n + 10], true);
          break;
      }
      for (let i = 0; i < 11; i++) {
        // Set the controller to connected if any button was pressed
        if (GAMEPADS[n + i] !== 0) GAMEPADS[n + 0] = 1;
      }
    });
  };
  document.onkeyup = (e) => {
    KEYBOARD_GAMEPAD_UPDATES.push(() => {
      const GAMEPADS = new Int32Array(memory.buffer, exports.GAMEPADS.value, 8);
      const n = 0; // gamepad id
      GAMEPADS[n + 0] = 1;
      switch (e.key) {
        case "ArrowUp":
        case "w": // up
          GAMEPADS[n + 1] = handleButtonPress(GAMEPADS[n + 1], false);
          break;
        case "ArrowDown":
        case "s": // down
          GAMEPADS[n + 2] = handleButtonPress(GAMEPADS[n + 2], false);
          break;
        case "ArrowLeft":
        case "a": // left
          GAMEPADS[n + 3] = handleButtonPress(GAMEPADS[n + 3], false);
          break;
        case "ArrowRight":
        case "d": // right
          GAMEPADS[n + 4] = handleButtonPress(GAMEPADS[n + 4], false);
          break;
        case "x": // a
          GAMEPADS[n + 5] = handleButtonPress(GAMEPADS[n + 5], false);
          break;
        case "z": // b
          console.log("z");
          GAMEPADS[n + 6] = handleButtonPress(GAMEPADS[n + 6], false);
          break;
        case "c": // x
          GAMEPADS[n + 7] = handleButtonPress(GAMEPADS[n + 7], false);
          break;
        case "v": // y
          GAMEPADS[n + 8] = handleButtonPress(GAMEPADS[n + 8], false);
          break;
        case " ": // start
          GAMEPADS[n + 9] = handleButtonPress(GAMEPADS[n + 9], false);
          break;
        case "Enter": // select
          GAMEPADS[n + 10] = handleButtonPress(GAMEPADS[n + 10], false);
          break;
      }
      for (let i = 0; i < 11; i++) {
        // Set the controller to connected if any button was pressed
        if (GAMEPADS[n + i] !== 0) GAMEPADS[n + 0] = 1;
      }
    });
  };
  const onFrameStart = () => {
    updateScreen();
    updateCursor();
    updateGamepad();
  };
  const onFrameEnd = () => {
    if (MOUSE_STATE === InputState.JustPressed)
      MOUSE_STATE = InputState.Pressed;

    if (MOUSE_STATE === InputState.JustReleased)
      MOUSE_STATE = InputState.Released;
  };
  const draw = () => {
    const GRAPHICS = new Int32Array(
      memory.buffer,
      exports.graphics(),
      exports.graphics_len()
    );
    drawingContext.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
    let i = 0;
    const len = GRAPHICS.length;
    while (i < len) {
      const tag = GRAPHICS[i++];
      // RECT
      // | tag: i32 | x: i32 | y: i32 | w: i32 | h: i32 | color: i32 |
      if (tag == -1) {
        const c = GRAPHICS[i++];
        const x = GRAPHICS[i++];
        const y = GRAPHICS[i++];
        const w = GRAPHICS[i++];
        const h = GRAPHICS[i++];
        // console.log("rect", { c, x, y, w, h });
        const rect = drawingContext.createImageData(w, h);
        new Uint32Array(rect.data.buffer).fill(PALETTE[c]);
        drawingContext.putImageData(rect, x, y);
        continue;
      }
      // SPRITE
      /// | tag: i32 | tex_id: i32 | color: i32 | sx: i32 | sy: i32 | sw: i32 | sh: i32 | dx: i32 | dy: i32 | dw: i32 | dh: i32 |
      if (tag == -2) {
        const tex_id = GRAPHICS[i++];
        const c = GRAPHICS[i++];
        const sx = GRAPHICS[i++];
        const sy = GRAPHICS[i++];
        const sw = GRAPHICS[i++];
        const sh = GRAPHICS[i++];
        const dx = GRAPHICS[i++];
        const dy = GRAPHICS[i++];
        const dw = GRAPHICS[i++];
        const dh = GRAPHICS[i++];
        // console.log("sprite", { tex_id, c, sx, sy, sw, sh, dx, dy, dw, dh });
        if (tex_id === 0) {
          drawingContext.drawImage(
            spritesheet_bmp,
            sx,
            sy,
            sw,
            sh,
            dx,
            dy,
            dw,
            dh
          );
        } else {
          // console.log("text", { tex_id, c, sx, sy, sw, sh, dx, dy, dw, dh });
          let bmp = font_5x5_bmp;
          if (tex_id === 1) bmp = font_5x5_bmp;
          if (tex_id === 2) bmp = font_5x8_bmp;
          if (tex_id === 3) bmp = font_8x8_bmp;
          const h = bmp.height / PALETTE_HEX.length;
          drawingContext.drawImage(bmp, sx, sy + h * c, sw, sh, dx, dy, dw, dh);
        }
        continue;
      }
      throw new Error("bad tag " + tag);
    }

    const dw = CANVAS_WIDTH * CANVAS_SCALE;
    const dh = CANVAS_HEIGHT * CANVAS_SCALE;
    // displayContext.clearRect(0, 0, dw, dh);
    const clearColor =
      PALETTE[new Int32Array(memory.buffer, exports.CLEAR_COLOR.value, 1)[0]];
    displayContext.fillRect(
      window.innerWidth / 2 - dw / 2,
      window.innerHeight / 2 - dh / 2,
      dw,
      dh
    );
    displayContext.drawImage(
      drawingCanvas,
      0,
      0,
      CANVAS_WIDTH,
      CANVAS_HEIGHT,
      window.innerWidth / 2 - dw / 2,
      window.innerHeight / 2 - dh / 2,
      dw,
      dh
    );
  };

  // Spritesheet
  let spritesheet_bmp: ImageBitmap | null = null;
  const spritesheet_bytes = new Uint8Array(
    memory.buffer,
    exports.SPRITESHEET.value,
    Math.min(mem.getInt32(exports.SPRITESHEET_LEN.value, true), 256 * 640 * 4)
  );
  const spritesheet_blob = new Blob([spritesheet_bytes], {
    type: "image/png",
  });
  spritesheet_bmp = await createImageBitmap(spritesheet_blob);

  const initFont = async (src: string) => {
    const res = await fetch(src);
    const blob = await res.blob();
    const bmp = await createImageBitmap(blob);
    const canvas = document.createElement("canvas");
    canvas.width = bmp.width;
    canvas.height = bmp.height * PALETTE_HEX.length;
    canvas.style.width = `${canvas.width}px`;
    canvas.style.height = `${canvas.height}px`;
    const ctx = canvas.getContext("2d");

    for (let i = 0; i < PALETTE_HEX.length; i++) {
      ctx.save();

      ctx.beginPath();
      ctx.rect(0, i * bmp.height, bmp.width, bmp.height);
      ctx.clip();

      ctx.globalCompositeOperation = "source-over";
      ctx.fillStyle = PALETTE_HEX[i];
      ctx.fillRect(0, i * bmp.height, bmp.width, bmp.height);

      ctx.globalCompositeOperation = "destination-in";
      ctx.drawImage(bmp, 0, i * bmp.height);

      ctx.restore();
    }
    return canvas;
  };

  const [font_5x5_bmp, font_5x8_bmp, font_8x8_bmp] = await Promise.all([
    initFont(FONT_SRC_5X5),
    initFont(FONT_SRC_5X8),
    initFont(FONT_SRC_8X8),
  ]);

  const run = exports.run;

  // const drawingCanvas = new OffscreenCanvas(CANVAS_WIDTH, CANVAS_HEIGHT);
  // drawingCanvas.width = CANVAS_WIDTH;
  // drawingCanvas.height = CANVAS_HEIGHT;
  const drawingCanvas = document.createElement("canvas");
  drawingCanvas.width = window.outerWidth;
  drawingCanvas.height = CANVAS_HEIGHT;
  drawingCanvas.style.width = "100vw";
  drawingCanvas.style.height = "100vh";
  const drawingContext = drawingCanvas.getContext("2d");
  drawingContext.imageSmoothingEnabled = false;
  console.log("LOL");

  const displayCanvas = document.createElement("canvas");
  displayCanvas.width = CANVAS_WIDTH;
  displayCanvas.height = CANVAS_HEIGHT;
  displayCanvas.style.width = "100%";
  displayCanvas.style.height = "100%";
  displayCanvas.style.background = "#111";
  const displayContext = displayCanvas.getContext("2d");

  const onresize = () => {
    const scale_w = window.innerWidth / CANVAS_WIDTH;
    const scale_h = window.innerHeight / CANVAS_HEIGHT;
    const max_scale = Math.max(scale_w, scale_h);
    const min_scale = Math.min(scale_w, scale_h);
    if (window.innerWidth < max_scale * CANVAS_WIDTH) {
      CANVAS_SCALE = min_scale;
    } else if (window.innerHeight < max_scale * CANVAS_HEIGHT) {
      CANVAS_SCALE = min_scale;
    } else {
      CANVAS_SCALE = max_scale;
    }
    displayCanvas.width = window.innerWidth;
    displayCanvas.height = window.innerHeight;
    displayContext.imageSmoothingEnabled = false;
  };
  onresize();
  window.onresize = onresize;

  // Background (clear)
  const bg = drawingContext.createImageData(CANVAS_WIDTH, CANVAS_HEIGHT);

  // Mouse wheel
  let wheel_delta_x = 0;
  let wheel_delta_y = 0;
  document.onwheel = (e) => {
    wheel_delta_x = e.deltaX * 0.25;
    wheel_delta_y = -e.deltaY * 0.25;
  };

  // Mouse
  let MOUSE_STATE = InputState.Released;
  let MOUSE_X = 0;
  let MOUSE_Y = 0;
  let IS_TOUCH_ENABLED =
    "ontouchstart" in window || navigator.maxTouchPoints > 0;

  const setMousePosition = (clientX: number, clientY: number) => {
    const rect = displayCanvas.getBoundingClientRect();
    const dw = CANVAS_WIDTH * CANVAS_SCALE;
    const dh = CANVAS_HEIGHT * CANVAS_SCALE;
    const x = clientX - rect.left - (window.innerWidth - dw) / 2;
    const y = clientY - rect.top - (window.innerHeight - dh) / 2;
    const canvasX =
      (x * displayCanvas.width) / (displayCanvas.width * CANVAS_SCALE);
    const canvasY =
      (y * displayCanvas.height) / (displayCanvas.height * CANVAS_SCALE);
    MOUSE_X = canvasX;
    MOUSE_Y = canvasY;
  };
  if (IS_TOUCH_ENABLED) {
    document.ontouchstart = (e) => {
      MOUSE_STATE = InputState.JustPressed;
      if (!e.touches.length) return;
      const { clientX, clientY } = e.touches[0];
      setMousePosition(clientX, clientY);
    };
    document.ontouchmove = (e) => {
      MOUSE_STATE = InputState.Pressed;
      if (!e.touches.length) return;
      const { clientX, clientY } = e.touches[0];
      setMousePosition(clientX, clientY);
    };
    document.ontouchend = (e) => {
      MOUSE_STATE = InputState.JustReleased;
      if (!e.touches.length) return;
      const { clientX, clientY } = e.touches[0];
      setMousePosition(clientX, clientY);
    };
  } else {
    document.onmousedown = (e) => {
      MOUSE_STATE = InputState.JustPressed;
      const { clientX, clientY } = e;
      setMousePosition(clientX, clientY);
    };
    document.onmousemove = (e) => {
      if (MOUSE_STATE === InputState.JustPressed) {
        MOUSE_STATE = InputState.Pressed;
      }
      const { clientX, clientY } = e;
      setMousePosition(clientX, clientY);
    };
    document.onmouseup = (e) => {
      MOUSE_STATE = InputState.JustReleased;
      const { clientX, clientY } = e;
      setMousePosition(clientX, clientY);
    };
  }

  document.body.append(displayCanvas);
  if (exports._start) exports._start();

  // const cover_bmp = await createImageBitmap(
  //   await (await fetch(cover_url)).blob()
  // );
  // ctx.drawImage(cover_bmp, 0, 0, 144, 144);

  setTimeout(() => {
    const fps = 60;
    const int = 1000 / fps;
    let then = 0;
    requestAnimationFrame(function loop(now) {
      try {
        // increment ticks at set fps
        const diff = now - then;
        if (diff > int) {
          then = now - (diff % int);
          onFrameStart();
          run();
          draw();
          onFrameEnd();
          const TICK = new Int32Array(memory.buffer, exports.TICK.value, 1);
          TICK[0] += 1;
        }
        if (WINDOW_ID === (window as any).ID) {
          requestAnimationFrame(loop);
        }
      } catch (err) {
        console.error(err);
      }
    });
  }, 200);
}

main().catch((err) => {
  console.error(err);
  document.body.innerText = "ERROR: " + err.message;
});
